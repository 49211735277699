<template>
  <svg width="467" height="343" viewBox="0 0 467 343" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M233.824 86.642L126.673 57.94C122.308 56.753 117.878 59.362 116.715 63.687C115.553 68.02 118.129 72.482 122.462 73.644L229.613 102.346C230.32 102.533 231.027 102.622 231.718 102.622C235.311 102.622 238.595 100.224 239.562 96.599C240.741 92.259 238.164 87.796 233.824 86.642Z"/>
    <path d="M257.795 98.5912C258.096 98.1682 258.348 97.7052 258.73 97.3472C258.356 97.7132 258.104 98.1682 257.795 98.5912Z"/>
    <path d="M256.933 99.8669C256.746 100.282 256.697 100.745 256.583 101.2C256.697 100.753 256.746 100.29 256.933 99.8669Z"/>
    <path d="M261.42 95.665C260.835 95.884 260.355 96.25 259.851 96.592C260.355 96.258 260.835 95.892 261.42 95.665Z"/>
    <path d="M256.616 336.588C255.169 332.337 257.437 327.72 261.688 326.273L272.442 322.607V108.996L266.98 110.89C262.721 112.369 258.104 110.118 256.64 105.875C256.307 104.908 256.209 103.924 256.242 102.957C256.242 103.046 256.193 103.128 256.193 103.217V323.51L86.729 280.265V59.355L82.762 60.656L70.472 71.711V286.574C70.472 290.289 72.992 293.524 76.593 294.451L262.315 341.841C262.982 342.012 263.656 342.093 264.323 342.093C260.924 342.099 257.762 339.969 256.616 336.588Z"/>
    <path d="M264.306 342.099C265.208 342.099 266.094 341.953 266.94 341.66H266.932C266.062 341.961 265.176 342.099 264.306 342.099Z"/>
    <path d="M74.032 46.9182C74.585 46.5362 75.137 46.1462 75.772 45.9102C75.121 46.1452 74.585 46.5282 74.032 46.9182Z"/>
    <path d="M414.823 49.4291C414.725 49.2581 414.555 49.1611 414.449 49.0061C415.246 50.1931 415.806 51.5501 415.888 53.0541C416.075 56.6631 413.848 59.9711 410.434 61.1501L404.061 63.3611L446.435 137.364L330.245 177.609L297.771 130.78C295.21 127.106 290.138 126.171 286.456 128.74C282.766 131.292 281.855 136.365 284.408 140.055L320.393 191.948C321.946 194.175 324.465 195.443 327.075 195.443C327.961 195.443 328.863 195.305 329.733 194.996L395.144 172.334V160.913C395.144 156.418 398.786 152.784 403.273 152.784C407.76 152.784 411.402 156.417 411.402 160.913V166.701L461.019 149.517C463.303 148.72 465.124 146.956 465.969 144.681C466.814 142.421 466.611 139.893 465.408 137.788L414.823 49.4291Z"/>
    <path d="M412.384 46.845C412.66 47.04 412.888 47.276 413.132 47.495C412.88 47.275 412.652 47.031 412.384 46.845Z"/>
    <path d="M409.775 45.6169C410.019 45.6819 410.23 45.8199 410.466 45.9019C410.222 45.8199 410.019 45.6819 409.775 45.6169Z"/>
    <path d="M395.151 280.761L272.434 322.607V333.963C272.434 336.475 271.272 338.848 269.288 340.385C268.573 340.938 267.776 341.36 266.939 341.653L405.897 294.263C409.189 293.141 411.4 290.044 411.4 286.573V166.7L395.143 172.333V280.76H395.151V280.761Z"/>
    <path d="M272.434 333.97V322.614L261.68 326.28C257.429 327.727 255.161 332.344 256.608 336.595C257.762 339.977 260.916 342.106 264.298 342.106C265.168 342.106 266.054 341.968 266.924 341.667H266.932C267.769 341.383 268.566 340.96 269.281 340.399C271.272 338.847 272.434 336.474 272.434 333.97Z"/>
    <path d="M403.28 152.784C398.793 152.784 395.151 156.417 395.151 160.913V172.334L411.408 166.701V160.913C411.409 156.418 407.767 152.784 403.28 152.784Z"/>
    <path d="M413.132 47.5029C413.62 47.9419 414.067 48.4379 414.449 49.0069C414.067 48.4379 413.636 47.9499 413.132 47.5029Z"/>
    <path d="M74.032 46.918C73.747 47.113 73.406 47.202 73.154 47.43L2.696 110.808C-0.206 113.409 -0.848 117.725 1.176 121.058C2.688 123.553 5.354 124.976 8.134 124.976C9.077 124.976 10.036 124.805 10.971 124.472L54.061 108.426C58.272 106.857 60.409 102.183 58.841 97.973C57.272 93.754 52.59 91.641 48.388 93.185L45.34 94.323L70.474 71.717V53.468C70.455 50.706 71.927 48.381 74.032 46.918Z"/>
    <path d="M410.466 45.9021C411.141 46.1381 411.791 46.4301 412.384 46.8451C411.799 46.4381 411.148 46.1451 410.466 45.9021Z"/>
    <path d="M86.713 53.469V59.362L216.006 16.931L378.577 54.989L261.64 95.543C261.559 95.567 261.502 95.641 261.421 95.673C262.323 95.332 263.282 95.096 264.299 95.096C268.786 95.096 272.428 98.729 272.428 103.225V109.004L404.054 63.362L400.705 57.518C398.478 53.624 399.827 48.65 403.721 46.423C405.631 45.342 407.793 45.131 409.769 45.626C409.712 45.61 409.671 45.577 409.615 45.561L217.485 0.585039C216.03 0.252039 214.51 0.31704 213.096 0.77204L76.048 45.747C75.942 45.78 75.869 45.869 75.763 45.91C76.649 45.577 77.584 45.341 78.584 45.341C83.079 45.341 86.713 48.974 86.713 53.469Z"/>
    <path d="M258.73 97.3471C259.055 97.0381 259.478 96.8431 259.852 96.5911C259.477 96.8431 259.055 97.0381 258.73 97.3471Z"/>
    <path d="M256.226 102.956C256.242 102.338 256.429 101.777 256.584 101.2C256.429 101.786 256.25 102.346 256.226 102.956Z"/>
    <path d="M257.795 98.5911C257.494 99.0141 257.145 99.3961 256.933 99.8671C257.153 99.3961 257.502 99.0141 257.795 98.5911Z"/>
    <path d="M86.713 59.354V53.461C86.713 48.966 83.071 45.332 78.584 45.332C77.584 45.332 76.649 45.568 75.763 45.901C75.129 46.137 74.576 46.527 74.023 46.909C71.918 48.38 70.455 50.697 70.455 53.461V71.71L82.745 60.655L86.713 59.354Z"/>
    <path d="M264.306 95.0881C263.282 95.0881 262.323 95.3241 261.428 95.6651C260.843 95.8931 260.355 96.2501 259.859 96.5921C259.485 96.8441 259.071 97.0391 258.737 97.3481C258.355 97.7141 258.103 98.1691 257.802 98.5921C257.501 99.0151 257.16 99.3971 256.94 99.8681C256.753 100.291 256.704 100.754 256.59 101.201C256.436 101.778 256.257 102.339 256.232 102.957C256.199 103.924 256.289 104.908 256.63 105.875C258.101 110.118 262.718 112.37 266.97 110.89L272.432 108.996V103.217C272.434 98.7211 268.793 95.0881 264.306 95.0881Z"/>
    <path d="M403.727 46.4141C399.833 48.6411 398.484 53.6161 400.711 57.5101L404.06 63.3541L410.433 61.1431C413.847 59.9561 416.074 56.6561 415.887 53.0471C415.806 51.5431 415.245 50.1941 414.448 48.9991C414.066 48.4301 413.627 47.9341 413.131 47.4951C412.887 47.2671 412.651 47.0321 412.383 46.8451C411.79 46.4391 411.139 46.1381 410.465 45.9021C410.229 45.8211 410.026 45.6831 409.774 45.6171C407.799 45.1131 405.637 45.3241 403.727 46.4141Z"/>
  </svg>
</template>

<script>
export default {
  name: 'EmptyIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
