






















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import BezeButton from '@/components/common/BezeButton.vue'
import EmptyIcon from '@/components/icons/EmptyIcon'
import ErrorIcon from '@/components/icons/ErrorIcon'
import SuccessIcon from '@/components/icons/SuccessIcon'

@Component({
  components: {
    BezeButton,
    EmptyIcon,
    ErrorIcon,
    SuccessIcon
  }
})
export default class LoadStatusMessage extends Vue {
  @Prop({ default: 'error' }) type!: 'empty' | 'error' | 'success'
  @Prop({ default: 'Ошибка при загрузке данных' }) title!: string
  @Prop({ default: 'Мы уже выясняем причины' }) subtitle!: string
  @Prop({ default: 'Вернуться на главную' }) btnText!: string
  @Prop({ default: '/' }) btnLink!: string
}
