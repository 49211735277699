<template>
  <svg width="512" height="512" viewBox="0 0 512 512" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M436.949 74.9805C388.605 26.6289 324.328 0 255.961 0C251.816 0 248.461 3.35938 248.461 7.5C248.461 11.6406 251.816 15 255.961 15C320.32 15 380.832 40.0664 426.344 85.5859C471.855 131.105 496.918 191.629 496.918 256C496.918 320.371 471.855 380.895 426.344 426.414C380.832 471.934 320.32 497 255.961 497C191.598 497 131.086 471.934 85.5742 426.414C40.0625 380.895 15 320.375 15 256C15 197.23 36.3867 140.625 75.2227 96.6094C113.719 52.9805 166.512 24.7461 223.875 17.1172C227.98 16.5703 230.867 12.8008 230.32 8.69531C229.773 4.58984 226 1.70703 221.898 2.24609C160.945 10.3555 104.863 40.3438 63.9766 86.6836C22.7188 133.441 0 193.574 0 256C0 324.379 26.625 388.668 74.9688 437.02C123.312 485.371 187.59 512 255.961 512C324.328 512 388.605 485.371 436.949 437.02C485.293 388.668 511.918 324.379 511.918 256C511.918 187.621 485.293 123.332 436.949 74.9805V74.9805Z"/>
    <path d="M283.055 234.676C287.195 234.676 290.555 231.32 290.555 227.176V143.535C290.555 133.16 282.113 124.719 271.738 124.719H240.18C229.805 124.719 221.363 133.16 221.363 143.535V286.422C221.363 296.801 229.805 305.238 240.18 305.238H271.738C282.113 305.238 290.555 296.801 290.555 286.422V258.219C290.555 254.078 287.195 250.719 283.055 250.719C278.91 250.719 275.555 254.078 275.555 258.219V286.422C275.555 288.527 273.844 290.238 271.738 290.238H240.18C238.074 290.238 236.363 288.527 236.363 286.422V143.535C236.363 141.43 238.074 139.719 240.18 139.719H271.738C273.844 139.719 275.555 141.43 275.555 143.535V227.176C275.555 231.32 278.91 234.676 283.055 234.676Z"/>
    <path d="M255.961 326.129C236.883 326.129 221.363 341.645 221.363 360.73C221.363 379.805 236.883 395.324 255.961 395.324C275.035 395.324 290.555 379.805 290.555 360.723C290.555 341.645 275.035 326.129 255.961 326.129ZM255.961 380.324C245.156 380.324 236.363 371.535 236.363 360.723C236.363 349.918 245.156 341.129 255.961 341.129C266.762 341.129 275.555 349.918 275.555 360.73C275.555 371.535 266.762 380.324 255.961 380.324Z"/>
  </svg>
</template>

<script>
export default {
  name: 'ErrorIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
