












































import { Component, Vue } from 'vue-property-decorator'
import WidgetTitle from '@/components/common/WidgetTitle.vue'
import Loader from '@/components/common/Loader.vue'
import LoadStatusMessage from '@/components/common/LoadStatusMessage.vue'
import ProductAPI from '@/api/products'

@Component({
  components: {
    WidgetTitle,
    Loader,
    LoadStatusMessage
  }
})
export default class Reviews extends Vue {
  isLoading = true
  hasLoadError = false
  reviewList: Array<Record<string, unknown>> = []

  mounted(): void {
    this.getReviewList()
  }

  getReviewList(): void {
    this.hasLoadError = false
    this.isLoading = true

    ProductAPI.getReviews().then(response => {
      this.reviewList = response?.data
    }).catch(() => {
      this.hasLoadError = true
    }).finally(() => {
      this.isLoading = false
    })
  }
}
